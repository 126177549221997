import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { fetchOrganization } from 'store/myAccount/requests';
import { useDispatchRequest } from '@redux-requests/react';
import { Organization } from 'types';
import { useCustomProfiles, useUploadCustomProfile } from 'store/queries/organizations/useCustomProfiles';

const CustomProfiles = () => {
  const [organizationId, setOrganizationId] = useState<string>('');
  const [profileName, setProfileName] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatchRequest = useDispatchRequest();
  const [organizationInfo, setOrganizationInfo] = useState<Partial<Organization>>();
  const uploadCustomProfileMutation = useUploadCustomProfile();
  const { data, isLoading } = useCustomProfiles(organizationId);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isOrgIdValid, setIsOrgIdValid] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const debouncedFetchOrganization = debounce((orgId: string) => {
    if (orgId) {
      fetchOrganizationData(orgId);
    }
  }, 300);

  const fetchOrganizationData = async (orgId: string) => {
    setIsFetching(true);
    try {
      const { data: orgData } = await dispatchRequest(fetchOrganization(orgId));
      if (orgData && organizationId.startsWith('org_')) {
        setOrganizationInfo(orgData);
        setIsOrgIdValid(true);
      } else {
        setOrganizationInfo(undefined);
        setIsOrgIdValid(false);
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
      setOrganizationInfo(undefined);
      setIsOrgIdValid(false);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (organizationId && organizationId.startsWith('org_')) {
      debouncedFetchOrganization(organizationId);
    } else {
      setOrganizationInfo(undefined);
      setIsOrgIdValid(false);
    }
    return () => {
      debouncedFetchOrganization.cancel();
    };
  }, [organizationId]);

  const readFileAsString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.name.endsWith('.ini')) {
      setSelectedFile(file);
    } else {
      alert('Please upload a valid .ini file');
    }
  };

  const handleUpload = () => {
    if (isOrgIdValid && organizationInfo) {
      setOpenModal(true);
    } else {
      alert('Invalid Organization ID or organization not found');
    }
  };

  const handleConfirmUpload = async () => {
    if (selectedFile) {
      const profileString = await readFileAsString(selectedFile);

      // Trigger the mutation
      uploadCustomProfileMutation.mutate(
        {
          customProfileString: profileString,
          customProfileName: profileName,
          orgId: organizationId,
        },
        {
          onSuccess: () => {
            console.log('Profile uploaded successfully');
            setOpenModal(false);
            setOrganizationId('');
            setProfileName('');
            setSelectedFile(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          },
          onError: (error) => {
            console.error('Error uploading profile:', error);
          },
        },
      );
    }
  };
  return (
    <>
      <Typography variant="h4">Custom Profiles</Typography>
      <Typography variant="subtitle1">Upload a GVD custom profile file (.ini) for a specific organization</Typography>
      <Typography variant="h6">Organization:</Typography>
      <span style={{ display: 'block', marginBottom: '16px' }}>{JSON.stringify(organizationInfo)}</span>

      {!isLoading && organizationInfo && (
        <>
          <Typography variant="h6">Custom Profiles</Typography>
          <span style={{ display: 'block', marginBottom: '16px' }}>{JSON.stringify(data)}</span>
        </>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpload();
        }}
      >
        <TextField
          label="Organization ID"
          variant="outlined"
          fullWidth
          margin="normal"
          value={organizationId}
          onChange={(e) => setOrganizationId(e.target.value)}
          helperText={(!isOrgIdValid || !organizationInfo) && organizationId !== '' ? 'Invalid Organization ID' : ''}
        />

        <TextField
          label="Profile Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
        />

        <TextField
          inputRef={fileInputRef}
          type="file"
          inputProps={{ accept: '.ini' }}
          fullWidth
          margin="normal"
          onChange={handleFileChange}
        />

        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={
            !selectedFile || !organizationId || !profileName || !isOrgIdValid || !organizationInfo || isFetching
          }
        >
          Upload
        </Button>
      </form>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Confirm Upload</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to upload the profile '{profileName}' for organization ID '{organizationId}'?
          </DialogContentText>
          <List>
            <ListItem>
              <ListItemText primary="Name:" secondary={organizationInfo?.name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Display Name:" secondary={organizationInfo?.displayName} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Organization Name:" secondary={organizationInfo?.id} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomProfiles;
