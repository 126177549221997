import { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInspectionId,
  getDisplayedDifferenceGroups,
  getDiscardedGroups,
  getFocusedDifference,
  getAllAnnotationsLoaded,
  getFocusedMatchIndex,
} from 'store';
import { Virtuoso } from 'react-virtuoso';
import { getStyleVariables } from 'styles/vars';
import { Button, CircularProgress, Grid, Theme } from '@mui/material';
import { GVTypography } from 'components/lib';
import DifferenceElement from './DifferenceElement';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@emotion/react';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { CTFModuleTypes } from 'types';
import { restoreAllDifferences } from 'store/request/differences/actions';
import RestoreIcon from '@mui/icons-material/Replay';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import debounce from 'lodash.debounce';

interface ListOfDifferencesProps {
  onlyDiscarded?: boolean;
  selectedModule: CTFModuleTypes | 'All';
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    containerPadding: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
    },
    differenceContainer: {
      paddingBottom: theme.spacing(0.5),
    },
    scrollBar: styleVariables.scrollBar,
    zeroResults: { display: 'flex', justifyContent: 'center', marginTop: theme.spacing(5) },
    discardedContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1.25, 1),
      margin: theme.spacing(0, 3, 1, 2),
      background: '#1F2126',
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    discardedResults: {
      color: '#FFFFFF',
      opacity: '60%',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    warningIcon: { width: '20px', height: '20px', marginRight: theme.spacing(1), color: '#8792AB61' },
    success: {
      color: theme.palette.success.main,
      cursor: 'default',
    },
    restoreAllButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: theme.spacing(1.25, 1),
      marginTop: theme.spacing(1),
    },
    restoreAllButton: {
      background: styleVariables.colors.darkGreyBackground,
      border: '1px solid #000',
      borderRadius: '3px',
      padding: '8px 12px !important',
      '&::after': {
        content: '"Restore All"', // This is important for ::after
        color: '#FFFFFF',
        fontSize: '12px',
        marginLeft: '10px',
        verticalAlign: 'bottom',
      },
    },
  };
});

const ListOfDifferences = (props: ListOfDifferencesProps) => {
  const { onlyDiscarded, selectedModule } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const styleVariables = getStyleVariables(theme);
  const virtuoso = useRef<any>(null);
  const displayedDifferences = useSelector(getDisplayedDifferenceGroups);
  const discardedGroups = useSelector(getDiscardedGroups);
  const focusedDifference = useSelector(getFocusedDifference);
  const focusedMatchIndex = useSelector(getFocusedMatchIndex);
  const currentDifferenceId = focusedDifference?.id;
  const currentDifferenceGroupId = focusedDifference?.groupId;
  const annotationsLoaded = useSelector(getAllAnnotationsLoaded);
  const [clickSelect, setClickSelect] = useState(false);
  const differenceList = onlyDiscarded ? discardedGroups : displayedDifferences;
  const inspectionId = useSelector(getInspectionId);
  const dispatch = useDispatch();
  // we need to keep comment open state here as virtuoso unmounts on scroll
  const [openComments, setOpenComments] = useState<Record<string, boolean>>(() => {
    const startingValues: Record<string, boolean> = {};
    if (differenceList) {
      differenceList.forEach((diff) => {
        startingValues[diff.id] = false;
      });
    }
    return startingValues;
  });

  const updateCommentOpen = (id: string, open: boolean) => {
    setOpenComments({ ...openComments, [id]: open });
  };

  useEffect(() => {
    let index;
    if (!currentDifferenceId && !currentDifferenceGroupId) {
      index = displayedDifferences.findIndex((difference) => difference.zoneIndex === focusedMatchIndex);
    } else {
      index = differenceList.findIndex(
        (difference) =>
          difference.id === currentDifferenceId ||
          (difference.groupId && difference.groupId === currentDifferenceGroupId),
      );
    }

    if (virtuoso !== null && virtuoso.current !== null && index > -1) {
      if (!clickSelect) {
        // if it's an annotation click, scroll to top.
        virtuoso.current.scrollToIndex({ index, align: 'start' });
      } else {
        // if difference card is only partially visible, scroll into view
        virtuoso.current.scrollIntoView({ index });
      }
    }
    setClickSelect(false);
    /* eslint-disable-next-line */
  }, [currentDifferenceId, currentDifferenceGroupId, focusedMatchIndex]);

  const hasZeroResults = differenceList.length === 0;

  const debouncedRestoreAllClick = useCallback(
    debounce(() => {
      dispatch(
        restoreAllDifferences(
          inspectionId,
          selectedModule === 'All' ? Object.values(CTFModuleTypes) : [selectedModule],
        ),
      );
    }, 300),
    [],
  );
  return (
    // don't display the differences to be clicked on until the corresponding annotations have loaded.
    annotationsLoaded ? (
      <Virtuoso
        ref={virtuoso}
        style={{ width: styleVariables.leftPanel.width, height: '100%', backgroundColor: '#0000003D' }}
        className={classes.scrollBar}
        totalCount={differenceList.length}
        components={{
          // used for top padding on the list
          Header: () => {
            if (hasZeroResults) {
              return (
                <GVTypography className={classes.zeroResults} emphasis="medium" fontWeight={400} fontSize="12px">
                  You have 0 {onlyDiscarded && 'discarded '}results.
                </GVTypography>
              );
            } else if (onlyDiscarded) {
              return (
                <>
                  <Grid className={classes.discardedContainer}>
                    <WarningIcon className={classes.warningIcon} />
                    <GVTypography
                      className={classes.discardedResults}
                      emphasis="medium"
                      fontWeight={400}
                      fontSize="12px"
                    >
                      Discarded results will not be included in your report.
                    </GVTypography>
                  </Grid>
                  <Grid className={classes.restoreAllButtonContainer}>
                    <Button size="small" id={`restore-all`}>
                      <GVIconButton
                        className={classes.restoreAllButton}
                        testId={`difference_card_restore_all_button`}
                        size="small"
                        onClick={() => {
                          debouncedRestoreAllClick();
                        }}
                        id={`restore-all-button`}
                        icon={<RestoreIcon className={`${classes.success}`} />}
                      />{' '}
                    </Button>
                  </Grid>
                </>
              );
            }

            return <Grid style={{ marginTop: '16px' }} />;
          },
        }}
        itemContent={(index) => (
          <Grid className={classes.differenceContainer}>
            <DifferenceElement
              key={differenceList[index].id}
              numberOfDifference={index}
              onClickSelect={setClickSelect}
              difference={differenceList[index]}
              isSelected={
                currentDifferenceId === differenceList[index].id ||
                (Boolean(currentDifferenceGroupId) && currentDifferenceGroupId === differenceList[index].groupId)
              }
              updateCommentOpen={updateCommentOpen}
              isCommentOpen={openComments[differenceList[index].id]}
            />
          </Grid>
        )}
      />
    ) : (
      <Grid container item className={classes.containerPadding}>
        <CircularProgress size={32} />
        <GVTypography>Applying Results..</GVTypography>
      </Grid>
    )
  );
};

export default ListOfDifferences;
