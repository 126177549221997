import { Typography, MenuItem, SelectChangeEvent, Theme, Grid } from '@mui/material';
import { usePDFPage } from 'pdftron';
import { useDispatch, useSelector } from 'react-redux';
import { app, getFocusedMatchIndex, getLastJobMatches, inspection } from 'store';
import { getStyleVariables } from 'styles/vars';
import { DocumentTypes } from 'types';
import { makeStyles } from 'tss-react/mui';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { useRef } from 'react';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    graphicsMenu: {
      overflow: 'hidden',
      width: '500px',
      maxHeight: '130px',
      padding: theme.spacing(0),
    },
    dropdownList: {
      marginTop: '10px',
    },
    list: {
      width: 300,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    listItem: {
      width: '330px',
      height: '50px',
    },
    menuPaper: {
      maxHeight: '350px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#393B3F',
        boxShadow: '0 0 1px 1px #474747',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: 'rgba(100, 102, 105, 0.6)',
        boxShadow: '0 0 1px 1px #393B3F',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#4e4e4e',
      },
    },
  };
});
const MatchesSelect = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const lastJobMatches = useSelector(getLastJobMatches);
  const focusedMatchIndex = useSelector(getFocusedMatchIndex);
  const sourcePdfPage = usePDFPage(DocumentTypes.target);
  const masterPdfPage = usePDFPage(DocumentTypes.source);

  const handleChange = (event: SelectChangeEvent<number>) => {
    if (event.target.value === focusedMatchIndex) {
      dispatch(app.actions.setFocusedMatchIndex(-1));
    } else {
      // deselect selected difference
      dispatch(inspection.actions.unfocusDifference());
      dispatch(inspection.actions.setInternalAnnotationsVisibilityToDefault());

      const { pageNumber: masterPageNumber } =
        lastJobMatches[event.target.value as number].matchDetails[0].masterLocation;
      const { pageNumber: sourcePageNumber } =
        lastJobMatches[event.target.value as number].matchDetails[0].sampleLocation;

      dispatch(app.actions.setFocusedMatchIndex(event.target.value as number));
      masterPdfPage.jumpToPage(masterPageNumber);
      sourcePdfPage.jumpToPage(sourcePageNumber);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, selectedValue: number) => {
    handleChange({
      target: { value: selectedValue },
    } as SelectChangeEvent<number>);
  };

  return (
    <Grid
      item
      display="flex"
      justifyContent="space-between"
      container
      direction="row"
      alignItems="center"
      className={classes.dropdownList}
    >
      <Grid container direction="column">
        <Grid item>
          <GVTextField
            select
            color="primary"
            fullWidth
            label={focusedMatchIndex === -1 ? 'Please select a match' : 'Match'}
            SelectProps={{
              fullWidth: true,
              value: focusedMatchIndex === -1 ? '' : focusedMatchIndex,
              MenuProps: {
                classes: { paper: classes.menuPaper },
              },
            }}
            sx={{
              width: '340px',
              height: '40px',
            }}
          >
            {lastJobMatches.map((match, repeatIndex) => (
              <MenuItem
                value={repeatIndex}
                key={`match-${repeatIndex}`}
                onClick={(event) => handleMenuClick(event, repeatIndex)}
              >
                P{match.matchDetails[0].masterLocation.pageNumber} to Match {match.index + 1} (Row {match.row} Column{' '}
                {match.column})
              </MenuItem>
            ))}
          </GVTextField>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchesSelect;
