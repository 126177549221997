import axiosInstanceQueries from '../queriesAxios';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';

interface CustomProfile {
  customProfileString: string;
  customProfileName: string;
  orgId: string;
}
async function fetchCustomProfiles(orgId?: string): Promise<any> {
  if (orgId) {
    return axiosInstanceQueries.get(`/organizations/custom-profile/${orgId}`).then((response) => response.data);
  }
  return axiosInstanceQueries.get('/organizations/custom-profile').then((response) => response.data);
}

export const useCustomProfiles = (orgId?: string) => {
  const queryInfo = useQuery({
    queryKey: ['customProfiles'],
    queryFn: () => fetchCustomProfiles(orgId),
  });
  return {
    ...queryInfo,
    customProfilesList: queryInfo.data || [],
  };
};
async function uploadCustomProfile({ customProfileString, customProfileName, orgId }: CustomProfile): Promise<void> {
  await axiosInstanceQueries.post('/organizations/custom-profile', {
    customProfileString,
    customProfileName,
    orgId,
  });
}

export const useUploadCustomProfile = (): UseMutationResult<void, Error, CustomProfile> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (profileData) => uploadCustomProfile(profileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customProfiles'] });
    },
  });
};
