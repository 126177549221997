import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PolygonInactiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8654_13115)">
          <path
            d="M2 14V4.05412H6.18907L7.55581 2H14V11.8824H11.041L9.67426 14H2ZM2.64237 4.71765V13.3365H9.33941L10.7062 11.2188H13.3713V2.67059H7.90433L6.53759 4.71765H2.64237Z"
            fill="#F1F2F6"
          />
          <path d="M12.5788 4.26588H11.5127V4.92941H12.5788V4.26588Z" fill="#F1F2F6" />
          <path d="M10.6784 4.26588H9.6123V4.92941H10.6784V4.26588Z" fill="#F1F2F6" />
          <path d="M8.79946 4.26588H7.7334V4.92941H8.79946V4.26588Z" fill="#F1F2F6" />
          <path d="M6.04555 10.9647H4.97949V11.6282H6.04555V10.9647Z" fill="#F1F2F6" />
          <path d="M4.15297 10.9647H3.08691V11.6282H4.15297V10.9647Z" fill="#F1F2F6" />
          <path d="M7.93813 10.9647H6.87207V11.6282H7.93813V10.9647Z" fill="#F1F2F6" />
          <path d="M9.82485 10.9647H8.75879V11.6282H9.82485V10.9647Z" fill="#F1F2F6" />
        </g>
        <defs>
          <clipPath id="clip0_8654_13115">
            <rect width="12" height="12" fill="white" transform="translate(2 2)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default PolygonInactiveIcon;
