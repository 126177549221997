import { useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DifferenceProps } from '../DifferenceElementView';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import GraphicsDifferenceIcon from 'components/icons/GraphicsDifferenceIcon/GraphicsDifferenceIcon';
import { useSelector } from 'react-redux';
import { getThumbnailsImagesPath } from 'store';
import { AspectRatio, Box } from '@mui/joy';

const useStyles = makeStyles()(() => {
  return {
    thumbnail: {
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      contentVisibility: 'auto',
    },
  };
});

const GraphicDifference = ({ difference, differenceNumber }: DifferenceProps) => {
  const { classes } = useStyles();
  const [imageError, setImageError] = useState({ source: false, target: false });
  const sourcedifferenceThumbnailBasePath = useSelector(getThumbnailsImagesPath('master'));
  const targetdifferenceThumbnailBasePath = useSelector(getThumbnailsImagesPath('sample'));

  const sourceThumbnailPath = `${sourcedifferenceThumbnailBasePath}${differenceNumber}.png`;
  const targetThumbnailPath = `${targetdifferenceThumbnailBasePath}${differenceNumber}.png`;

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { id } = event.currentTarget;
    if (id.includes('source-thumbnail')) {
      setImageError((prevState) => ({ ...prevState, source: true }));
    } else if (id.includes('target-thumbnail')) {
      setImageError((prevState) => ({ ...prevState, target: true }));
    }
  };

  const sourceThumbnail = (
    <Grid container justifyContent="center" alignItems="center">
      {sourcedifferenceThumbnailBasePath && !imageError.source ? (
        <Box id="force_dark_mode" sx={{ width: 300, p: 1 }} style={{ borderRadius: '4px', paddingLeft: 0 }}>
          <AspectRatio objectFit="cover" style={{ borderRadius: '4px' }}>
            <img
              id={`source-thumbnail-${differenceNumber}`}
              src={sourceThumbnailPath}
              alt={`Source Difference # ${differenceNumber}`}
              className={classes.thumbnail}
              onError={handleImageError}
            />
          </AspectRatio>
        </Box>
      ) : (
        <GraphicsDifferenceIcon />
      )}
    </Grid>
  );

  const targetThumbnail = (
    <Grid container justifyContent="center" alignItems="center">
      {targetdifferenceThumbnailBasePath && !imageError.target ? (
        <Box
          id="force_dark_mode"
          sx={{ width: 300, borderRadius: 'xl', p: 1 }}
          style={{ borderRadius: '4px', paddingLeft: 0 }}
        >
          <AspectRatio objectFit="cover" style={{ borderRadius: '4px' }}>
            <img
              id={`target-thumbnail-${differenceNumber}`}
              src={targetThumbnailPath}
              alt={`New Difference # ${differenceNumber}`}
              className={classes.thumbnail}
              onError={handleImageError}
            />
          </AspectRatio>
        </Box>
      ) : (
        <GraphicsDifferenceIcon />
      )}
    </Grid>
  );
  return (
    <DifferenceDescription
      difference={difference}
      sourceDescription={sourceThumbnail}
      targetDescription={targetThumbnail}
    />
  );
};

export default GraphicDifference;
