import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { app, inspection, getPageLoadedSelector, getExcludedPage, getShowDifferenceBoxes } from 'store';
import { DocumentType, DocumentStates, DocumentTypes, PDFTRON_DEFAULT_TOOL, StoreState } from 'types';
import { isInspectionUrl } from 'utils/location';
import { getStyleVariables } from 'styles/vars';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { actions } from 'pdftron/docManager/reducer';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';
import {
  handleAnnotationSelected,
  handleAnnotationDeselected,
  handleAnnotationChanged,
} from '../../components/PDFViewer/EventHandlers';
import PDFAnnotationManager from '../../components/PDFViewer/PDFAnnotationManager';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { getBackendURL } from 'store/interceptors';
import { AnnotationAction } from 'components/PDFViewer/Utils';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    pdfViewer: styleVariables.pdfViewer,
  };
});

interface PDFViewerProps {
  file: string;
  document: DocumentType;
  fileId: string;
}

const PDFViewer = (props: PDFViewerProps) => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const store = useStore();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const hasLoaded = useSelector(getPageLoadedSelector)[props.document];
  const showDifferenceBoxes = useSelector(getShowDifferenceBoxes);
  const location = useLocation();
  const getExcludedPages = useCallback((): Array<number> => {
    const state = store.getState() as StoreState;
    const excludedPage = getExcludedPage(state)[props.document];
    return excludedPage;
  }, [props.document, store]);
  const [filePath, setFilePath] = useState<string>();
  const url = getBackendURL();

  const overrideMouseLeftDown = useCallback(
    function override(
      this:
        | Core.Tools.TextUnderlineCreateTool
        | Core.Tools.TextStrikeoutCreateTool
        | Core.Tools.TextHighlightCreateTool
        | Core.Tools.RectangleCreateTool,
      e: React.MouseEvent,
      excludedPages: Array<number>,
      mouseLeftDown: (e: React.MouseEvent) => void,
    ) {
      const target = e.target as HTMLDivElement;
      const pageNum = parseInt(target.id.replace('pageWidgetContainer', ''), 10);
      // if page number is in excludedPages array don't call original mouseLeftDown handler.
      if (excludedPages.indexOf(pageNum) > -1 && excludedPages.length !== 0) {
        dispatch(app.actions.setSnackMessage({ message: 'This page has been excluded', type: 'error' }));
      } else {
        mouseLeftDown.apply(this, [e]);
      }
    },
    [dispatch],
  );

  const overrideKeyDown = function override(
    this:
      | Core.Tools.TextUnderlineCreateTool
      | Core.Tools.TextStrikeoutCreateTool
      | Core.Tools.TextHighlightCreateTool
      | Core.Tools.RectangleCreateTool,
    e: React.KeyboardEvent,
  ) {
    e.preventDefault();
  };

  // Document is loading
  useEffect(() => {
    dispatch(
      inspection.actions.setLoadingState({
        documentType: props.document,
        documentState: DocumentStates.LOADING,
      }),
    );
  }, [dispatch, props.document]);

  useEffect(() => {
    if (props.file) {
      const filePathComponents = props.file.split('/');
      filePathComponents[filePathComponents.length - 1] = encodeURIComponent(
        filePathComponents[filePathComponents.length - 1],
      );
      setFilePath(`${url}${filePathComponents.join('/')}`);
    }
  }, [props.file]);

  useEffect(() => {
    if (filePath) {
      const instance = PDFManagerFactory.getViewer(props.document)?.instance;
      if (instance) {
        dispatch(
          inspection.actions.setLoadingState({
            documentType: props.document,
            documentState: DocumentStates.LOADING,
          }),
        );
        instance.UI.loadDocument(filePath);
        dispatch(actions.replaceFileId({ documentType: props.document, fileId: props.fileId }));
      }
    }
  }, [filePath, props.document]);

  useEffect(() => {
    const filePathComponents = props.file.split('/');
    filePathComponents[filePathComponents.length - 1] = encodeURIComponent(
      filePathComponents[filePathComponents.length - 1],
    );
    const initialFilePath = `${url}${filePathComponents.join('/')}`;

    WebViewer(
      {
        licenseKey: import.meta.env.VITE_PDFTRON_LICENSE_KEY,
        path: '/webviewer',
        initialDoc: initialFilePath,
        useDownloader: false,
        fullAPI: true,
        disabledElements: [
          'header',
          'toolsHeader',
          'toolStylePopup',
          'stylePopup',
          'textPopup',
          'contextMenuPopup',
          'viewControlsOverlay',
          'menuOverlay',
          'toolsOverlay',
          'pageNavOverlay',
          'linkButton',
          'annotationCommentButton',
          'annotationStyleEditButton',
          'annotationRedactButton',
          'annotationCropButton',
          'annotationGroupButton',
          'annotationUngroupButton',
          'annotationDeleteButton',
          'calibrateButton',
          'fileAttachmentDownload',
        ],
        css: '/pdfTronStyles.css',
      },
      viewerRef.current as HTMLDivElement,
    ).then((instance: WebViewerInstance) => {
      const { documentViewer, annotationManager } = instance.Core;
      // Disable link generation so that they no longer get created/turned into annotations.
      documentViewer.disableAutomaticLinking();

      dispatch(actions.loadWebViewer({ documentType: props.document, instance, fileId: props.fileId }));
      instance.UI.setTheme('dark');

      // Set default tool to Pan
      instance.UI.setToolMode(PDFTRON_DEFAULT_TOOL);

      const strikeoutMouseLeftDown = instance.Core.Tools.TextStrikeoutCreateTool.prototype.mouseLeftDown;
      // eslint-disable-next-line no-param-reassign
      instance.Core.Tools.TextStrikeoutCreateTool.prototype.mouseLeftDown = function overrideStrikeOut(e: any) {
        overrideMouseLeftDown.call(this, e, getExcludedPages(), strikeoutMouseLeftDown);
      };
      const zoneMouseLeftDown = instance.Core.Tools.TextHighlightCreateTool.prototype.mouseLeftDown;
      // eslint-disable-next-line no-param-reassign
      instance.Core.Tools.TextHighlightCreateTool.prototype.mouseLeftDown = function overrideZone(e: any) {
        overrideMouseLeftDown.call(this, e, getExcludedPages(), zoneMouseLeftDown);
      };

      const graphicZoneMouseLeftDown = instance.Core.Tools.RectangleCreateTool.prototype.mouseLeftDown;
      // eslint-disable-next-line no-param-reassign
      instance.Core.Tools.RectangleCreateTool.prototype.mouseLeftDown = function overrideZone(e: any) {
        overrideMouseLeftDown.call(this, e, getExcludedPages(), graphicZoneMouseLeftDown);
      };

      // disable all the key down events(hotkeys) on annotations
      // eslint-disable-next-line no-param-reassign
      instance.Core.Tools.TextStrikeoutCreateTool.prototype.keyDown = function overrideStrikeOutKeyDown(e: any) {
        overrideKeyDown.call(this, e);
      };

      // eslint-disable-next-line no-param-reassign
      instance.Core.Tools.TextHighlightCreateTool.prototype.keyDown = function overrideStrikeOutKeyDown(e: any) {
        overrideKeyDown.call(this, e);
      };

      // eslint-disable-next-line no-param-reassign
      instance.Core.Tools.RectangleCreateTool.prototype.keyDown = function overrideStrikeOutKeyDown(e: any) {
        overrideKeyDown.call(this, e);
      };

      // Turn Off all hotkeys, we can always use hotkeys.on() if we want to make some of them handled again
      instance.UI.hotkeys.off();
      // enable space hotkey to enable panning
      instance.UI.hotkeys.on(String(instance.UI.hotkeys.Keys.SPACE));

      const getAnnotationCommentElement = (annotation: Core.Annotations.Annotation) => {
        const comment = annotation.getContents();
        const user = annotation.Author;
        const date = annotation.DateModified;

        let dateModified;
        if (date) {
          const dateFormatted = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          });
          const time = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          dateModified = `${dateFormatted} | ${time}`;
        } else {
          dateModified = `N/A`;
        }

        const commentContainer = document.createElement('div');
        const authorDiv = document.createElement('div');
        // hard coded css. Will have to adjust multiple areas if mockup changes
        authorDiv.style.cssText =
          'font-style: normal; font-weight: 600; font-size: 11px; line-height: 15px; padding-bottom: 8px;';
        authorDiv.appendChild(document.createTextNode(`${user || 'Author'}`));
        const commentDiv = document.createElement('div');
        commentDiv.style.cssText =
          'font-style: normal; font-weight: normal; font-size: 11px; line-height: 15px; color: rgba(255, 255, 255, 0.87); padding-bottom: 8px';
        commentDiv.appendChild(document.createTextNode(`${comment || 'N/A'}`));
        const dateDiv = document.createElement('div');
        dateDiv.style.cssText =
          'font-style: normal; font-weight: normal; font-size: 10px; line-height: 14px; color: rgba(255, 255, 255, 0.6);';
        dateDiv.appendChild(document.createTextNode(dateModified));
        commentContainer.appendChild(authorDiv);
        commentContainer.appendChild(commentDiv);
        commentContainer.appendChild(dateDiv);
        commentContainer.style.cssText = 'padding: 16px;';
        return commentContainer;
      };

      const getDividerElement = () => {
        const hr = document.createElement('hr');
        hr.style.backgroundColor = 'black';
        hr.style.height = '1px';
        hr.style.border = 'none';
        hr.style.margin = '0px';
        return hr;
      };

      // To show overlays only to a non-GV/internal annotation
      instance.UI.setAnnotationContentOverlayHandler((annotation: Core.Annotations.Annotation) => {
        if (!PDFManagerFactory.getPDFDocManager()?.isGVAnnotation(annotation) && annotation.getContents() !== '') {
          let annotationArray: Core.Annotations.Annotation[] = [annotation, ...annotation.getReplies()];
          annotationArray.sort(
            (comment1, comment2) =>
              new Date(comment1.DateModified).getTime() - new Date(comment2.DateModified).getTime(),
          );
          if (annotationArray.length > 3) {
            annotationArray = annotationArray.slice(0, 3);
          }
          const container = document.createElement('div');
          annotationArray.forEach((annot, index, array) => {
            const annotationComment = getAnnotationCommentElement(annot);
            container.appendChild(annotationComment);
            if (index + 1 < array.length) {
              container.appendChild(getDividerElement());
            }
          });
          if ([annotation, ...annotation.getReplies()].length > 3) {
            const numReplies = document.createTextNode(
              `+ ${[annotation, ...annotation.getReplies()].length - 3} replies`,
            );
            const numRepliesDiv = document.createElement('div');
            numRepliesDiv.style.cssText =
              'font-style: normal; font-weight: normal; font-size: 11px; line-height: 15px; padding-left: 16px; padding-bottom: 16px;';
            numRepliesDiv.appendChild(numReplies);
            container.appendChild(numRepliesDiv);
          }
          // container has negative margin to cover the change in background color compared to its parent the div AnnotationsContentOverlay
          // the parent container has a max-width of 215px, -32px for the 16px padding on each side. 183px width left.
          container.style.cssText =
            'max-width: 183px; min-width: 183px; background: #2D2F34; box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2); border-radius: 3px; margin: -8px;';
          return container;
        }
        return false;
      });

      annotationManager.addEventListener(
        'annotationSelected',
        (annotations: Core.Annotations.Annotation[], action: any) => {
          if (action === AnnotationAction.SELECTED && annotations[0].getCustomData('liveText') !== 'true') {
            handleAnnotationSelected(annotations, store, props.document);
          } else {
            handleAnnotationDeselected(annotations[0], props.document);
          }
        },
      );

      // @pdftron-refactor Move to saga
      documentViewer.addEventListener('pageNumberUpdated', (pageNumber: number) => {
        dispatch(
          inspection.actions.setCurrentPage({
            documentType: props.document,
            currentPage: pageNumber,
          }),
        );
      });

      instance.Core.Annotations.SelectionModel.useDashedLine = !showDifferenceBoxes;
      instance.Core.Annotations.SelectionModel.showPaddingWhenAnnotationIsSmall = false;
      instance.Core.Annotations.SelectionModel.selectionOutlineExtraPadding = 0;
      instance.Core.Annotations.SelectionModel.defaultNoPermissionSelectionOutlineColor =
        new instance.Core.Annotations.Color(0, 0, 0, 0); // no default PDFTron outline on differences

      // Listen for new annotations
      // @pdftron-refactor Move to saga
      documentViewer
        .getAnnotationManager()
        .addEventListener(
          'annotationChanged' as unknown as any,
          (annotations: Core.Annotations.Annotation[], action: string) => {
            handleAnnotationChanged(dispatch, props.document, annotations, action, store);
          },
        );
    });

    return () => {
      dispatch(actions.unloadViewers(props.document));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // deselect annotations when user clicks outside
    const handleClickOutside = (e: any) => {
      if (!isInspectionUrl(location)) {
        if (!viewerRef.current?.contains(e.target)) {
          if (props.document === DocumentTypes.source) {
            PDFAnnotationManager.getInstance(DocumentTypes.source)?.deselectAllAnnotations();
          } else {
            PDFAnnotationManager.getInstance(DocumentTypes.target)?.deselectAllAnnotations();
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [hasLoaded, props.document]);

  return <div data-testid={`pdf_viewer_${props.document}`} className={classes.pdfViewer} ref={viewerRef} />;
};
export default PDFViewer;
