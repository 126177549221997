import React from 'react';
import { Typography, List, Grid, Theme, ListItemText, ListItemButton, FormControl } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import { GraphicsMatchingMode, GraphicsSensitivity, GraphicsOptionKey, GraphicsOptionValue } from 'types';
import { getOneToOneSelected, inspection } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GVSelect from 'components/lib/GVSelect/GVSelect';
import { useCustomProfiles } from 'store/queries/organizations/useCustomProfiles';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    graphicsMenu: {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginTop: '5px',
      marginBottom: '10px',
      overflow: 'hidden',
      height: '54vh',
      width: '310px',
      maxHeight: '110px',
      backgroundColor: '#35373C',
      borderRadius: '4px',
      border: '2px solid #292B30',
    },
    subheader: {
      padding: theme.spacing(1.5),
    },
    subheaderTitle: {
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 600,
      color: styleVariables.colors.hint,
      letterSpacing: 1.25,
    },
    list: {
      width: 300,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    checkbox: {
      padding: theme.spacing(0),
      '&.Mui-disabled': {
        opacity: 0.38,
        color: theme.palette.text.secondary,
        '& .MuiIconButton-label:after': {
          display: 'none',
        },
      },
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '&.Mui-checked': {
        '& .MuiIconButton-label:after': {
          content: '""',
          left: 4,
          top: 4,
          height: 12,
          width: 12,
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: -1,
        },
      },
    },
    listItem: {
      width: '277px',
    },
    optionsFont: {
      fontSize: '11px',
      alignItems: 'center',
      color: '#AEAFB1',
    },
    tooltip: {
      maxWidth: 200,
      maxHeight: 400,
      zIndex: 1300,
      padding: theme.spacing(0.5),
    },
    zoneInfoIcon: {
      marginLeft: '5px',
      fontSize: '15px',
      cursor: 'pointer',
      verticalAlign: 'middle',
      color: '#FFFFFF',
    },
  };
});

type CustomProfile = {
  id: string;
  customProfileName: string;
};

const GraphicsMenu = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const oneToOneSelected = useSelector(getOneToOneSelected);
  const { customProfilesList } = useCustomProfiles();

  const sensitivityOptions = [
    { value: 'Standard', label: 'Standard' },
    { value: 'High', label: 'High' },
    ...customProfilesList.map((profile: CustomProfile) => ({
      value: profile.id,
      label: profile.customProfileName,
    })),
  ];

  const handleCheckboxChange = () => {
    const updatedValue = oneToOneSelected ? GraphicsMatchingMode.OneToMany : GraphicsMatchingMode.OneToOne;

    dispatch(
      inspection.actions.setGraphicsOptions({
        key: GraphicsOptionKey.MatchingMode,
        value: updatedValue as GraphicsOptionValue,
      }),
    );
  };

  const handleSensitivityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value;

    let sensitivityValue: GraphicsOptionValue | string;
    switch (selectedValue) {
      case 'Standard':
        sensitivityValue = GraphicsSensitivity.LOW;
        break;
      case 'High':
        sensitivityValue = GraphicsSensitivity.HIGH;
        break;
      default:
        sensitivityValue = selectedValue as string;
        break;
    }

    dispatch(
      inspection.actions.setGraphicsOptions({
        key: GraphicsOptionKey.Sensitivity,
        value: sensitivityValue,
      }),
    );
  };

  return (
    <div className={classes.graphicsMenu}>
      <Grid item display="flex" justifyContent="space-between" container direction="row" alignItems="center">
        <List className={classes.list} dense>
          <Grid container direction="column">
            <Grid item>
              <FormControl className={classes.listItem}>
                <GVSelect
                  defaultValue={'Standard'}
                  label="Sensitivity"
                  data={sensitivityOptions}
                  id="graphics-sensitivity"
                  onChange={handleSensitivityChange}
                />
              </FormControl>
            </Grid>
            <Grid item style={{ marginTop: '-10px' }}>
              <ListItemButton className={classes.listItem} onClick={() => handleCheckboxChange()}>
                <Grid item>
                  <GVCheckbox
                    className={classes.checkbox}
                    checked={oneToOneSelected}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Grid>
                <Grid item style={{ marginLeft: '-10px' }}>
                  <ListItemText
                    className={classes.listItem}
                    primary={
                      <Typography className={classes.optionsFont}>
                        {'Pages in same order for faster inspection'}
                        <GVTooltip
                          title="Verify can process your inspection faster by matching like pages together. 
                    ONLY select this option if the sort order is exactly the same —otherwise the inspection will fail."
                          placement="top-end"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <InfoOutlinedIcon className={classes.zoneInfoIcon} />
                        </GVTooltip>
                      </Typography>
                    }
                  />
                </Grid>
              </ListItemButton>
            </Grid>
          </Grid>
        </List>
      </Grid>
    </div>
  );
};

export default GraphicsMenu;
